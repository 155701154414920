import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import issuerPortal from './issuerPortal';

const { REACT_APP_API_URL_TREX_FACTORY } = process.env;

const api = createApi({
  reducerPath: 'trexFactory',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_TREX_FACTORY,
    prepareHeaders,
  }),
  endpoints: builder => ({
    token: builder.query({
      query: ({ tokenId }) => ({
        url: `/tokens/${tokenId}`,
      }),
    }),
    updateToken: builder.mutation({
      query: ({ tokenId, ...payload }) => ({
        method: 'PUT',
        url: `/tokens/${tokenId}`,
        body: payload,
      }),
      onQueryStarted: async (_req, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(issuerPortal.util.invalidateTags([{ type: 'tokens', id: 'all' }]));
      },
    }),
    claimIssuers: builder.query({
      query: params => ({
        url: `/claim-issuers`,
        params, // {network}
      }),
    }),
    claimTypes: builder.query({
      query: () => ({
        url: `/claim-types`,
      }),
    }),
    setClaimIssuer: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `/claim-issuers`,
        body, // {network}
      }),
    }),
    setClaimTypes: builder.mutation({
      query: () => ({
        method: 'POST',
        url: `/claim-types`,
      }),
    }),
    complianceTemplates: builder.query({
      query: () => ({
        url: `/compliance/template`,
      }),
    }),
    saveComplianceTemplate: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `/compliance`,
        body,
      }),
    }),
    updateCompliance: builder.mutation({
      query: ({ tokenId, customConfiguration, customComplianceType }) => ({
        method: 'PUT',
        url: `/tokens/${tokenId}/compliance`,
        body: {
          customComplianceType,
          ...(customComplianceType === 'CustomConfiguration' ? { customConfiguration } : {}),
        },
      }),
    }),
    updateTokenIdentityRegisterStorage: builder.mutation({
      query: ({ tokenId, identityStorageSmartContractAddress }) => ({
        method: 'PUT',
        url: `/tokens/${tokenId}/identityStorage`,
        body: { identityStorageSmartContractAddress },
      }),
    }),
    deployToken: builder.mutation({
      query: ({ tokenId }) => ({
        method: 'POST',
        url: `/tokens/${tokenId}/deploy`,
      }),
      async onQueryStarted({ tokenId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          issuerPortal.util.updateQueryData(`tokens`, undefined, draft => {
            const ind = draft.findIndex(el => el.id === tokenId);
            if (ind >= 0) {
              draft[ind].status = 'DEPLOYING';
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export default api;

export const {
  useLazyTokenQuery,
  useTokenQuery,
  useLazyClaimIssuersQuery,
  useLazyClaimTypesQuery,
  useUpdateTokenMutation,
  useSetClaimIssuerMutation,
  useSetClaimTypesMutation,
  useSaveComplianceTemplateMutation,
  useLazyComplianceTemplatesQuery,
  useUpdateComplianceMutation,
  useUpdateTokenIdentityRegisterStorageMutation,
  useDeployTokenMutation,
} = api;
